import React from "react";
import image from "../style/images/pic404.svg";
import { useHistory } from "react-router-dom";
const Page404 = () => {
  const history = useHistory();
  return (
    <div className="left-side-content">
      <h1>Pagina non trovata... 😨</h1>
      <div className="image-side-container">
        <img src={image} className="image-side" />
      </div>
      <button
        className="regular-button"
        onClick={() => {
          history.goBack();
        }}
      >
        Torna indietro
      </button>
    </div>
  );
};

export default Page404;
