import logoGen1 from "../style/images/intesa-s-paolo.png";
import LoroRiskapp from "../style/images/logoRiskapp.svg";
import React from "react";

const Footer = () => {
  return (
    <div className="external-footer-credits">
      <div className="center-of-footer">
        <p>Powered by</p>
        <a
          href="https://www.intesasanpaolo.com/"
          rel="no-referrer"
          target="_blank"
        >
          <img
            src={logoGen1}
            className="cargeas-logo"
            alt="Logo Generic"
          />
        </a>
      </div>
      <div className="riskapp-footer">
        <a href="https://riskapp.it" target="_blank" rel="noreferrer">
          <img src={LoroRiskapp} className="riskapp-logo" alt="Logo RiskAPP" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
