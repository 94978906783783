import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import LocationPin from "./LocationPin";

(function () {
  var cors_api_host = "cors-anywhere.herokuapp.com";
  var cors_api_url = "https://" + cors_api_host + "/";
  var slice = [].slice;
  var origin = window.location.protocol + "//" + window.location.host;
  var open = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function () {
    var args = slice.call(arguments);
    var targetOrigin = /^https?:\/\/([^\/]+)/i.exec(args[1]);
    if (
      targetOrigin &&
      targetOrigin[0].toLowerCase() !== origin &&
      targetOrigin[1] !== cors_api_host
    ) {
      args[1] = cors_api_url + args[1];
    }
    return open.apply(this, args);
  };
})();

const Map = () => {
  const [coordinates, setCoordinates] = useState([45.4058142, 11.8600823]);

  return (
    <div className="google-map">
      <GoogleMapReact
        //bootstrapURLKeys={{ key: "AIzaSyAtq8lI60qNk0X6mKADDAhbiIwrF7qLepw" }}
        yesIWantToUseGoogleMapApiInternals={true}
        center={coordinates}
        defaultCenter={coordinates}
        defaultZoom={18}
      >
        <LocationPin lat={coordinates[0]} lng={coordinates[1]} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
