import React from "react";
import HeatMap from "react-heatmap-grid";
var bgcolor="#00c32f";
const xLabels = ["", "", "",""];
const yLabels = ["", "", ""];
const xLabelsVisibility = new Array(24)
  .fill(0)
  .map((_, i) => (i % 2 === 0 ? true : false));

  var data=[
    ['Data Leakage','Phishing','MalWare Activity','Spam'],
    ['Ransomware','Email Security','Server Security','Network Devices Security'],
    ['Endpoint Security','Fraud Sensitivity','IoT Security','Encryption']
]
const showValue=(value)=>{
    var number="";
    if(value=='Ransomware' || value=='Email Security' || value=='Server Security')
        bgcolor='red';
    else    
        bgcolor="#00c32f";
    if(value=='Data Leakage' || value=='Email Security' || value=='Encryption' )
        number=1;
    else if(value=='Server Security')
        number=3;
    else if(value=='Network Devices Security')
        number=4;
    else
        number=0;
    return(
        <div style={{display:'inline-flex',width:'100%'}}>
            <div style={{width:'90%',textAlign:'left'}}>
            {value}
            </div>
            <div style={{width:'10%',textAlign:'right',paddingRight:'10px'}}>
            {number}
            </div>
        </div>
    )
}
const HeatMapChart=()=> {
  return (
    <div style={{ fontSize: "13px",width:'100%',marginLeft:'-32px'}}>
      <HeatMap
        xLabels={xLabels}
        yLabels={yLabels}
        xLabelsLocation={"bottom"}
        xLabelsVisibility={xLabelsVisibility}
        xLabelWidth={0}
        height={50}
        data={data}
        squares={false}
        cellRender={value => 
            showValue(value)
        }
        cellStyle={() => ({
          background: bgcolor,
          fontSize: "11.5px",
          color: "#000",
          textAlign:'left',
          paddingLeft:'10px',
        })}
        legendSettings={{
            position: 'Bottom',
            width: '20%',
            alignment: 'Near',
            showLabel: true,
            labelDisplayType: 'None',
            enableSmartLegend: true
        }}
      />
      <div style={{width:'100%',display:'flex',marginTop:'20px',marginLeft:'40px'}}>
          <div style={{width:'45px',height:'19px',backgroundColor:'#00c32f',marginRight:'5px'}} ></div>
          <div style={{marginRight:'5px',marginBottom:'-3px'}}>Rischio Basso</div>
          <div style={{width:'45px',height:'19px',backgroundColor:'#ffcd24',marginRight:'5px'}}></div>
          <div style={{marginRight:'5px',marginBottom:'-3px'}}>Rischio Medio</div>
          <div style={{width:'45px',height:'19px',backgroundColor:'#ff0000',marginRight:'5px'}}></div>
          <div style={{marginRight:'5px',marginBottom:'-3px'}}>Rischio Alto</div>
      </div>
    </div>
  );
}

export default HeatMapChart;

