import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { changeSelectedBusinessData } from "../redux/actions";
import Map from "../map/Map";
import Questions from "../style/images/Questions.svg";
import Header from "../commonviews/Header";
import Footer from "../commonviews/Footer";
import UserInfo from "../commonviews/UserInfo";

const ConfirmRegistryData = () => {
  const [businessName, setBusinessName] = useState("Pizzeria Rossi");
  const [vat, setVatNumber] = useState("00964345345");
  const [ragSoc, setRagSoc] = useState("Ragione sociale");
  const [address, setAddress] = useState("Via Lepanto 8, Padova, PD, 35100");
  const [ateco, setAteco] = useState(
    "56.10.11 - Ristorazione con somministrazione"
  );
  const [cap, setCap] = useState("35100");
  const [provincia, setProvincia] = useState("PD");
  const [comune, setComune] = useState("Padova");
  const [fatturato, setFatturato] = useState("€ 136.321,00");
  const [numDip, setNumDip] = useState("4");
  const dispatch = useDispatch();
  const selectedBusinessRedux = useSelector(
    (store) => store.reducer.selectedBusiness
  );
  const [selectedBusiness, setSelectedBusiness] = useState({});
  const history = useHistory();
  const onSubmit = (event) => {
    event.preventDefault();
    if (!_.isEmpty(selectedBusiness)) {
      dispatch(changeSelectedBusinessData(selectedBusiness));
      history.push("/user-form");
    }
  };

  useEffect(() => {
    if (_.isEmpty(selectedBusinessRedux)) {
      // temporaneamente commentato per la fase di sviluppo
      // history.push("/");
    } else {
      setSelectedBusiness({ ...selectedBusinessRedux });
    }
  }, [selectedBusinessRedux]);

  if (!_.isEmpty(selectedBusiness)) {
    return (
      <React.Fragment>
        <div className="card-header">
          <h1>Conferma i dati che abbiamo trovato!</h1>
          <h2>Se necessario puoi modificarli:</h2>
        </div>

        <form className="form-card-content" onSubmit={onSubmit}>
          <div className="form-data-and-image-container">
            <div className=" element-in-confirm-data-page">
              <div className="form-confirm-page">
                <div className="section-content">
                  <p>Nome azienda:</p>
                  <input
                    className="input-field input-confirm-registry"
                    placeholder="Nome azienda"
                    value={businessName}
                    onChange={(event) => {
                      if (event) setBusinessName(event.target.value);
                    }}
                  />
                </div>
                <div className="section-content">
                  <p>Partita IVA:</p>
                  <input
                    className="input-field input-confirm-registry "
                    placeholder="Scrivi qui!"
                    value={vat}
                    onChange={(event) => {
                      if (event) setVatNumber(event.target.value);
                    }}
                  />
                </div>
                <div className="section-content">
                  <p>Codice Ateco:</p>
                  <input
                    className="input-field input-confirm-registry"
                    placeholder="Scrivi qui!"
                    value={ateco}
                    onChange={(event) => {
                      if (event) setAteco(event.target.value);
                    }}
                  />
                </div>
                <div className="section-content">
                  <p>Indirizzo:</p>
                  <input
                    className="input-field input-confirm-registry"
                    placeholder="Scrivi qui!"
                    value={address}
                    onChange={(event) => {
                      if (event) setAddress(event.target.value);
                    }}
                  />
                </div>

                <div className="section-content">
                  <p style={{ marginBottom: "0.5rem" }}>Fatturato: </p>
                  <input
                    className="input-field input-confirm-registry"
                    placeholder="Scrivi qui!"
                    value={fatturato}
                    onChange={(event) => {
                      if (event) setFatturato(event.target.value);
                    }}
                  />
                </div>
                <div className="section-content">
                  <p style={{ marginBottom: "0.5rem" }}>
                    Numero di dipendenti:{" "}
                  </p>
                  <input
                    className="input-field input-confirm-registry"
                    placeholder="Scrivi qui!"
                    value={numDip}
                    onChange={(event) => {
                      if (event) setNumDip(event.target.value);
                    }}
                  />
                </div>
              </div>
              <Map />
            </div>
            <div className="image-side-container element-in-confirm-data-page">
              <img
                src={Questions}
                className="image-side"
                style={{ padding: "0" }}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="regular-button"
              style={{ marginBottom: "0.2rem" }}
            >
              Conferma
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  } else return <h1>loading</h1>;
};

export default ConfirmRegistryData;
