import React from "react";
import { useSelector } from "react-redux";
import "react-step-progress-bar/styles.css";
import _ from "lodash";
const UserInfo = () => {
  const selectedBusiness = useSelector(
    (store) => store.reducer.selectedBusiness
  );
  if (!_.isEmpty(selectedBusiness)) {
    return (
      <div className="user-info-internal-container">
        <div className="user-data">
          <div>
            <h2 className="user-data-business-name">
              <strong>{selectedBusiness.name}</strong>
            </h2>
          </div>
          <div>
            <h2>{selectedBusiness.vat}</h2>
          </div>
          <div>
            <h2>{selectedBusiness.address}</h2>
            <h2>
              {selectedBusiness.cap} {selectedBusiness.location}
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <h2
              className="user-data-lesser-info"
              style={{ paddingRight: "0.5rem" }}
            >
              Fatturato:
            </h2>
            <h2>{selectedBusiness.fatturato}</h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <h2
              className="user-data-lesser-info"
              style={{ paddingRight: "0.5rem" }}
            >
              Dipendenti:
            </h2>
            <h2>{selectedBusiness.dip}</h2>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default UserInfo;
