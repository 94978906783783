import React, { useEffect } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const PolicyCard = ({ policy, index, showMonthlyPrice }) => {
  const history = useHistory();
  const selectPolicy = (event) => {
    let alreadySelected = document.getElementsByClassName("selected-policy");
    for (let i = 0; i < alreadySelected.length; ++i) {
      alreadySelected[i].classList.remove("selected-policy");
    }
    document.getElementById(`policy-${index}`).classList.add("selected-policy");
  };

  useEffect(() => {
  }, [showMonthlyPrice]);
  
  const loadCoveredInfo = (info) => {
    return (
      <div className="policy-info-row">
        <span className={info.covered ? "" : "crossed-out"}>{info.name}</span>
        <span
          className={
            info.covered ? "icon-policy is-covered" : "icon-policy not-covered"
          }
        >
          {info.covered ? <CheckOutlined /> : <CloseOutlined />}
        </span>
      </div>
    );
  };

  return (
    <div
      className="policy-card-container"
      id={`policy-${index}`}
      onClick={(event) => {
        // selectPolicy(event);
      }}
    >
      <div className="policy-card-header">
        <h1 className="policy-name">{policy.name}</h1>
        <div className="prices-container">
          <div className="prices-whole"></div>
          <h1 style={{ fontSize: "1.8em" }}>
            <div className="monthly-price">
              {showMonthlyPrice ? (
                <React.Fragment>
                  <span className="barred price barred-price">
                    {policy.monthlyBarredPrice}
                    <span className="decimals">.00</span>
                  </span>
                  <div>
                    {policy.monthlyPrice}
                    <span className="decimals">.00</span>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="barred price barred-price">
                    {policy.barredPrice}
                    <span className="decimals">.00</span>
                  </span>
                  <div>
                    {policy.price}
                    <span className="decimals">.00</span>
                  </div>
                </React.Fragment>
              )}
            </div>
          </h1>
        </div>
      </div>
      <div className="policy-card-body">
        <div className="policies-list">
          {policy.covers.map((c) => loadCoveredInfo(c))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <button
            className="regular-button choice-button grounded-radiants"
            onClick={(event) => {
              selectPolicy(event);
              history.push("/overview");
            }}
          >
            Seleziona
          </button>
        </div>
      </div>
    </div>
  );
};

export default PolicyCard;
