import {
  SAVE_SELECTED_BUSINESS,
  CHANGE_SELECTED_BUSINESS_DATA,
} from "./actions";

const INITIAL_STATE = {
  selectedBusiness: {
    name: "Pizzeria Rossi",
    address: "Via Lepanto 8",
    cap: "35200",
    location: "Padova, PD",
    ragSociale: "Prova",
    vat: "00964345345",
    fatturato: "€ 136.321,00",
    dip: 4,
  },
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_SELECTED_BUSINESS:
      return {
        ...state,
        selectedBusiness: { ...action.payload },
      };
    case CHANGE_SELECTED_BUSINESS_DATA:
      return {
        ...state,
        selectedBusiness: { ...action.payload },
      };
    default:
      return {
        ...state,
      };
  }
}
