import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import PolicyCard from "./PolicyCard";
import ExplanationCard from "./ExplanationCard";
import map1Img from "../style/images/map1.png";
import map2Img from "../style/images/map2.png";
import "../style/switch.css";
import Coffee from "../style/images/rischi/coffeeshop.svg";
import { Table } from 'antd';
import HeatMapChart from './HeatMap'

const risks = [
  {
    text: "La lampada potrebbe cadere",
    left: "47%",
    top: "3%",
  },
  {
    text: "Il cliente potrebbe scottarsi",
    left: "55%",
    top: "50%",
  },
  {
    text: "La macchinetta potrebbe andare in corto circuito",
    left: "30%",
    top: "60%",
  },
  {
    text: "Un cliente potrebbe inciampare",
    left: "70%",
    top: "80%",
  },
];

const policies = [
  {
    name: "Base",
    price: "€300",
    monthlyPrice: "€25",
    barredPrice: "€350",
    monthlyBarredPrice: "€30",
    lowerPercent: 10,
    covers: [
      {
        name: "Incendio e rischi accessori",
        covered: true,
      },
      {
        name: "Lastre",
        covered: false,
      },
      {
        name: "Elettronica e guasti macchine",
        covered: false,
      },
      {
        name: "Furto",
        covered: false,
      },
      {
        name: "Responsabilità civile - R.C.T - R.C.O.",
        covered: true,
      },
      {
        name: "Impianti fotovoltaici - solari termici",
        covered: false,
      },
      {
        name: "Tutela legale",
        covered: false,
      },
      {
        name: "CatNat",
        covered: false,
      },
      {
        name: "Cyber",
        covered: false,
      },
      {
        name: "Assistenza",
        covered: true,
      },
    ],
  },
  {
    name: "Standard",
    price: "€450",
    monthlyPrice: "€38",
    barredPrice: "€525",
    monthlyBarredPrice: "€44",
    lowerPercent: 15,
    covers: [
      {
        name: "Incendio e rischi accessori",
        covered: true,
      },
      {
        name: "Lastre",
        covered: true,
      },
      {
        name: "Elettronica e guasti macchine",
        covered: true,
      },
      {
        name: "Furto",
        covered: false,
      },
      {
        name: "Responsabilità civile - R.C.T - R.C.O.",
        covered: true,
      },
      {
        name: "Impianti fotovoltaici - solari termici",
        covered: false,
      },
      {
        name: "Tutela legale",
        covered: true,
      },
      {
        name: "CatNat",
        covered: true,
      },
      {
        name: "Cyber",
        covered: true,
      },
      {
        name: "Assistenza",
        covered: true,
      },
    ],
  },
  {
    name: "Premium",
    price: "€700",
    monthlyPrice: "€58",
    barredPrice: "€850",
    monthlyBarredPrice: "€70",
    lowerPercent: 20,
    covers: [
      {
        name: "Incendio e rischi accessori",
        covered: true,
      },
      {
        name: "Lastre",
        covered: true,
      },
      {
        name: "Elettronica e guasti macchine",
        covered: true,
      },
      {
        name: "Furto",
        covered: true,
      },
      {
        name: "Responsabilità civile - R.C.T - R.C.O.",
        covered: true,
      },
      {
        name: "Impianti fotovoltaici - solari termici",
        covered: true,
      },
      {
        name: "Tutela legale",
        covered: true,
      },
      {
        name: "CatNat",
        covered: true,
      },
      {
        name: "Cyber",
        covered: true,
      },
      {
        name: "Assistenza",
        covered: true,
      },
    ],
  },
];

const columns = [
  {
    title: 'Tipologia Rischio',
    dataIndex: 'tipoRischio',
    width:'25%'
  },
  
  {
    title: 'Livello Rischio' ,
    dataIndex: 'livello',
    width:'15%'

  },
  
  {
    title: 'Tipologia di scenario',
    dataIndex: 'tipoScenario',
    width:'60%'
  }
];

const data = [
  {
    key: '1',
    tipoRischio: 'Email Security',
    livello: 'Alto',
    tipoScenario: 'L’organizzazione è esposta a spam e tentativi di phishing perché il Security Policy Framework (SPF) non è abilitato'
  },
  {
    key: '2',
    tipoRischio: 'Server Security',
    livello:'Alto',
    tipoScenario: 'L’organizzazione utilizza software con vulnerabilità note sfruttabili',
  },
  {
    key: '3',
    tipoRischio: 'Network Devices Security',
    livello:'Medio',
    tipoScenario: 'L’organizzazione utilizza software con vulnerabilità note sfruttabili'
  },
  {
    key: '4',
    tipoRischio:'Server Security',
    livello:'Medio',
    tipoScenario:'I server web dell’organizzazione sono esposti a multiple minacce a causa di un’errata configurazione delle porte di rete aperte'
  },
  {
    key: '5',
    tipoRischio: 'Data Leakage',
    livello:'Basso',
    tipoScenario: 'L’organizzazione è esposta ad accessi non autorizzati alle risorse interne e a potenziali danni alla reputazione a causa delle credenziali dei dipendenti trapelate nella darknet',
  },
];

const riskCardInfo=[
  {
    title:'Alluvione',
    image:map1Img,
    chart:null,
    text:<div>Nella zona di <b>Via Lepanto 8, 35100 Padova</b> il pericolo Alluvione risulta essere <b>Alto</b></div>
  },
  {
    title:'furto',
    image:map2Img,
    chart:null,
    text:<div>
            È dimostrato che il livello di furti è inversamente proporzionale
            al livello di illuminazione. Nella zona di <b>Via Lepanto 8, 35100 Padova </b>
            il livello di illuminazione risulta essere Medio-Basso, conseguentemente il
            livello di pericolosità di Furto è <b>Medio</b></div>
  },
  {
    title:'Cyber',
    image: '',
    chart: <HeatMapChart />,
    text: <Table columns={columns}  dataSource={data}  pagination={false} className="ant-table-content"/>
  },
]

const ShowPolicies = () => {
  const [showMonthlyPrice, setShowPrice] = useState(true);
  const selectedBusinessRedux = useSelector(
    (store) => store.reducer.selectedBusiness
  );
  const history = useHistory();
  useEffect(() => {
    if (_.isEmpty(selectedBusinessRedux)) {
      // temporaneamente commentato per la fase di sviluppo
      // history.push("/");
    }
  }, [selectedBusinessRedux]);

  useEffect(() => {
    let riskElement = document.getElementById("first-risk");
    if (
      !_.isEmpty(riskElement) &&
      riskElement.childElementCount < risks.length
    ) {
      risks.forEach((risk) => {
        const point = document.createElement("div");
        const info = document.createElement("div");
        info.classList.add("risk-info");
        point.classList.add("risk-point");
        point.style.top = risk.top;
        point.style.left = risk.left;
        info.innerHTML = `<p>${risk.text}</p>`;
        info.style.left = `calc(${risk.left} - 15%)`;
        info.style.top = `calc(${risk.top} - 15%)`;
        document.getElementById("first-risk").appendChild(point);
        point.addEventListener("mouseenter", () => {
          riskElement.appendChild(info);
        });
        point.addEventListener("mouseleave", () => {
          riskElement.removeChild(info);
        });
        point.addEventListener("touchstart", () => {
          riskElement.appendChild(info);
          setTimeout(() => {
            riskElement.removeChild(info);
          }, 2500);
        });
      });
    }
  }, []);
  useEffect(() => {
    if (_.isEmpty(selectedBusinessRedux)) {
      // temporaneamente commentato per la fase di sviluppo
      // history.push("/");
    }
  }, [selectedBusinessRedux]);
  if (!_.isEmpty(selectedBusinessRedux)) {
    return (
      <React.Fragment>
        <div style={{overflow:'scroll',width:'100%',scrollbarWidth:'none'}}>
          <div
            className="left-side-content"
            style={{ justifyContent: "space-between",backgroundColor:'#f1f1f1'}}
          >
            <div className="card-header">
              <h1>Ecco la nostra analisi ...</h1>
            </div>
            <div className="risks-image-container" >
              <div id="first-risk">
                <img src={Coffee} className="risks-image"/>
              </div>
            </div>
            </div>
            {
              riskCardInfo.map((cardInfo) => (
                    <ExplanationCard image={cardInfo['image']} title={cardInfo.title} chart={cardInfo.chart} text={cardInfo['text']}/>
                ))
            }
            <div
              className="card-header card-header-policies" style={{marginTop:'60px'}}
            >
              <h1 style={{ marginBottom: 0 }}>Le nostre proposte</h1>
              <div style={{marginBottom:'10px',marginTop:'60px'}}>
                <label className="switch">
                  <input
                    type="checkbox"
                    id="togBtn"
                    onChange={() => {
                      setShowPrice(!showMonthlyPrice);
                    }}
                  />
                  <div className="slider round" />
                </label>
              </div>
            </div>
            <div className="policies-container" style={{marginBottom:'15px'}}>
              {policies.map((policy, index) => (
                <PolicyCard
                  policy={policy}
                  index={index}
                  showMonthlyPrice={showMonthlyPrice}
                />
              ))}
            </div>
        </div>
      </React.Fragment>
    );
  } else return <h1>loading</h1>;
  
};

export default ShowPolicies;
