export const SAVE_SELECTED_BUSINESS = "SAVE_SELECTED_BUSINESS";
export const CHANGE_SELECTED_BUSINESS_DATA = "CHANGE_SELECTED_BUSINESS_DATA";

export const saveSelectedBusiness = (business) => {
  return {
    type: SAVE_SELECTED_BUSINESS,
    payload: { ...business },
  };
};

export const changeSelectedBusinessData = (business) => {
  return {
    type: CHANGE_SELECTED_BUSINESS_DATA,
    payload: { ...business },
  };
};
