import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Coffee from "../style/images/rischi/coffeeshop.svg";

const risks = [
  {
    text: "La lampada potrebbe cadere",
    left: "47%",
    top: "3%",
  },
  {
    text: "Il cliente potrebbe scottarsi",
    left: "55%",
    top: "50%",
  },
  {
    text: "La macchinetta potrebbe andare in corto circuito",
    left: "30%",
    top: "60%",
  },
  {
    text: "Un cliente potrebbe inciampare",
    left: "70%",
    top: "80%",
  },
];

const NeedsAnalysisPage = () => {
  const selectedBusinessRedux = useSelector(
    (store) => store.reducer.selectedBusiness
  );

  const history = useHistory();

  useEffect(() => {
    if (_.isEmpty(selectedBusinessRedux)) {
      // temporaneamente commentato per la fase di sviluppo
      // history.push("/");
    }
  }, [selectedBusinessRedux]);

  useEffect(() => {
    let riskElement = document.getElementById("first-risk");
    if (
      !_.isEmpty(riskElement) &&
      riskElement.childElementCount < risks.length
    ) {
      risks.forEach((risk) => {
        const point = document.createElement("div");
        const info = document.createElement("div");
        info.classList.add("risk-info");
        point.classList.add("risk-point");
        point.style.top = risk.top;
        point.style.left = risk.left;
        info.innerHTML = `<p>${risk.text}</p>`;
        info.style.left = `calc(${risk.left} - 15%)`;
        info.style.top = `calc(${risk.top} - 15%)`;
        document.getElementById("first-risk").appendChild(point);
        point.addEventListener("mouseenter", () => {
          riskElement.appendChild(info);
        });
        point.addEventListener("mouseleave", () => {
          riskElement.removeChild(info);
        });
        point.addEventListener("touchstart", () => {
          riskElement.appendChild(info);
          setTimeout(() => {
            riskElement.removeChild(info);
          }, 2500);
        });
      });
    }
  }, []);

  if (!_.isEmpty(selectedBusinessRedux)) {
    return (
      <React.Fragment>
        <div className="card-header">
          <h1>Ecco i rischi che stai correndo...</h1>
        </div>

        <div className="risks-image-container">
          <div id="first-risk">
            <img src={Coffee} className="risks-image" />
          </div>
        </div>

        <button
          onClick={() => {
            history.push("/show-policies");
          }}
          className="regular-button"
          style={{ marginBottom: "0.2rem" }}
        >
          Come posso proteggermi?
        </button>
      </React.Fragment>
    );
  } else return <h1>loading</h1>;
};

export default NeedsAnalysisPage;
