import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./style/customer-journey.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import RegistryPage from "./registrypage/components/RegistryPage";
import ConfirmRegistryData from "./confirmregistry/ConfirmRegistryData";
import NeedsAnalysisPage from "./needsanalysis/NeedsAnalysisPage";
import ShowPolicies from "./showpolicies/ShowPolicies";
import Header from "./commonviews/Header";
import UserInfo from "./commonviews/UserInfo";
import Footer from "./commonviews/Footer";
import UserForm from "./userform/UserForm";
import Overview from "./overview/Overview";
import ConfirmPayment from "./confirmpayment/ConfirmPayment";
import Confirmation from "./confirmation/Confirmation";
import Polizza from "./polizza/polizza";
import Page404 from "./commonviews/Page404";
import Wizard from "./commonviews/Wizard";
const App = () => {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "") {
      setPage(0);
    } else {
      setShowUserInfo(true);
      setShowWizard(true);
      if (location.pathname === "/confirm-data") {
        setPage(0);
        setShowUserInfo(false);
      } else if (location.pathname === "/user-form") setPage(25);
      else if (location.pathname === "/needs-analysis") setPage(50);
      else if (location.pathname === "/polizza") setPage(50);
      else if (location.pathname === "/show-policies") setPage(75);
      else if (location.pathname === "/overview") setPage(75);
      else if (location.pathname === "/confirm-payment") setPage(100);
      else if (location.pathname === "/confirmation") setPage(100);
      else setShowUserInfo(false);
    }
  }, [location]);
  return (
    <div className="content-external-container">
      <div className="card-container">
        <Header showWizard={showWizard} page={page} />

        <div className="content" style={{ flexDirection: "column" }}>
          <div
            style={{ display: "flex", flexDirection: "row", height: "100%" }}
          >
            {showUserInfo ? <UserInfo /> : null}
            <div className="left-side-content">
              {/*showWizard && (
                <div className="wizard-container">
                  <Wizard page={page} />
                </div>
              )*/}
              <Switch>
                <Route exact path="/" component={RegistryPage} />
                <Route path="/confirm-data" component={ConfirmRegistryData} />
                <Route path="/user-form" component={UserForm} />
                <Route path="/needs-analysis" component={NeedsAnalysisPage} />
                <Route path="/show-policies" component={ShowPolicies} />
                <Route path="/overview" component={Overview} />
                <Route path="/confirm-payment" component={ConfirmPayment} />
                <Route path="/confirmation" component={Confirmation} />
                <Route path="/polizza" component={Polizza} />
                <Route path="*" component={Page404} />
              </Switch>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
