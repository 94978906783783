import React, { useState } from "react";

const RegistrySearch = ({ sendShowResults }) => {
  const [showResults, setShowResults] = useState(false);
  const onSubmit = (event) => {
    event.preventDefault();
    setShowResults(true);
    sendShowResults(true);
  };

  return (
    <React.Fragment>
      <div className="card-header">
        <h1>Ottieni una quotazione!</h1>
        {!showResults ? (
          <h2>
            Inserisci la ragione sociale o la partita IVA dell'azienda da
            quotare:
          </h2>
        ) : (
          <h2>Seleziona la tua azienda tra quelle proposte:</h2>
        )}
      </div>
      <form
        className="form-card-content"
        style={{ height: "auto" }}
        onSubmit={onSubmit}
      >
        <div className="card-content">
          <div
            className="section-content"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input className="input-field" placeholder="Scrivi qui!" />
            {!showResults && (
              <button
                type="submit"
                className="regular-button"
                style={{ marginTop: "2rem" }}
              >
                Conferma
              </button>
            )}
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default RegistrySearch;
