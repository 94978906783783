import React from "react";

const ExplanationCard = ({image,title,chart,text}) => {
  const renderChart=()=>{
    if(title!="Cyber"){
      return (
        <div className="chart-main">
          <h1 className="chart-name">{title}</h1>
          <div style={{display:'flex'}}>
            <br />
            <div style={{width:'100%'}}>   
            {chart}      
              <img src={image} className="chart-image" alt="map"/>
            </div>
            <div style={{width:'100%'}} className="chart-text">{text}</div>
          </div>
        </div>
      )
    }else{
      return (
        <div className="chart-main">
          <h1 className="chart-name">{title}</h1>
          <div style={{display:'flex'}}>
              <div className="heatmap-container" style={{width:'100%'}}>   
                <div className="heatmap-inf-main">
                  <div className="heatmap-inf" style={{backgroundColor:'#ffcd24'}}>
                    <div className="hetamap-inf-num"><b>6.53</b></div>
                    <div>                
                      <div className="hetamap-inf-name"><b>Cyber Risk Score</b></div>
                      <div className="hetamap-inf-scale"><b>(0-10 scale)</b></div>
                    </div>
                  </div>
                  <div className="heatmap-inf">
                    <div className="hetamap-inf-name"><b>Industry Avg. Cyber Risk Score</b></div>
                    <div className="hetamap-inf-name-sec1"><b>7.4</b></div>
                  </div>
                  <div className="heatmap-inf">
                    <div className="hetamap-inf-name"><b>Identified Cyber Risks</b></div>
                    <div className="hetamap-inf-name-sec"><b>10</b></div>
                  </div>
                  <div className="heatmap-inf">
                    <div className="hetamap-inf-name"><b>Immediate Risk</b></div>
                    <div className="hetamap-inf-name-sec"><b>Email Security</b></div>
                  </div>
                </div>
                {chart} 
            </div>
            <div style={{width:'100%'}} className="chart-text">{text}</div>
          </div>
        </div>
      )
      }
  }
  return (
    <div
      className="chart-card-container exp-main"
      style={{    
      width:'94%',
      marginTop:'60px',
      marginRight:'auto',
      marginLeft:'auto'
    }}
      onClick={(event) => {
        // selectPolicy(event);
      }}
    >
      <div className="policy-card-body">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            padding:'20px'
          }}
        >
          {renderChart()}
        </div>
      </div>
    </div>
  );
};

export default ExplanationCard;
