import React from "react";
import Checkout from "../style/images/DifferentShopping.svg";
import { useHistory } from "react-router-dom";
import Stripe from "../style/images/stripe_screenshot.png";

const ConfirmPayment = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="left-side-inside-content">
        <div style={{ width: "100%", height: "100%" }}>
          <div className="card-header">
            <h1>Ci sei quasi!</h1>
            <h2>Collegati con paypal per effettuare il pagamento:</h2>
          </div>

          <div className="form-data-and-image-container">
            <div className="card-content">
              <img src={Stripe} className="stripe-preview" />
            </div>

            <div className="image-side-container" style={{ width: "50%" }}>
              <img
                src={Checkout}
                className="image-side"
                style={{ maxWidth: "30rem" }}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="regular-button"
        onClick={() => {
          history.push("/confirmation");
        }}
        style={{ marginBottom: "0.2rem" }}
      >
        Conferma
      </button>
    </React.Fragment>
  );
};

export default ConfirmPayment;
