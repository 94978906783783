import React from "react";
import { useHistory } from "react-router-dom";
import { Table } from 'antd';

const radioValues=(nameRisk,isCheckedSi,isCheckedNo)=>{
  var radio;
  if(isCheckedSi || isCheckedNo){
     radio=<div style={{textAlign:'center',color:'#6d7072'}}>
    <input type="radio" value="si" checked={isCheckedSi} name={nameRisk}/> Si
    <input type="radio" value="no" checked={isCheckedNo} name={nameRisk} style={{marginLeft:'20px'}}/> No
  </div>;
  }else{
     radio=<div style={{textAlign:'center',color:'#6d7072'}}>
    <input type="radio" value="si"  name={nameRisk}/> Si
    <input type="radio" value="no"  name={nameRisk} style={{marginLeft:'20px'}}/> No
  </div>;
  }

  return radio;
}

var textareaValue=<div style={{textAlign:'center'}}><textarea cols="30" rows="2" style={{borderRadius:'5px',border:'1px solid #dfdfdf'}}></textarea></div>;
var contractCol=<div style={{textAlign:'center'}}>Coperta noi?</div>;
var bankCol=<div style={{textAlign:'center'}}>Coperta con altri?</div>;
var noteCol=<div style={{textAlign:'center'}}>Note</div>;

const columns = [
  {
    title: 'Rischio',
    dataIndex: 'rischio',
  },
  {
    title: contractCol,
    dataIndex: 'contract',

  },
  {
    title: bankCol,
    dataIndex: 'bank',

  },
  {
    title: noteCol,
    dataIndex: 'note',
  }
];

const data = [
  {
    key: '1',
    rischio: 'Incendio',
    contract: radioValues('CIncendio',false,true),
    bank: radioValues('BIncendio',false,false),
    note: textareaValue,
  },
  {
    key: '2',
    rischio: 'Furto',
    contract: radioValues('CFurto',false,true),
    bank: radioValues('BFurto',false,false),
    note: textareaValue,
  },
  {
    key: '3',
    rischio: 'Nat Cat',
    contract: radioValues('CNatCat',true,false),
    bank: radioValues('BNatCat',false,false),
    note: textareaValue,
  },
  {
    key: '4',
    rischio: 'RCT/RCO',
    contract:radioValues('CRCTRCO',true,false),
    bank: radioValues('BRCTRCO',false,false),
    note: textareaValue,
  },
  {
    key: '5',
    rischio: 'Tutela legale',
    contract:radioValues('CTutelaLegale',false,true),
    bank: radioValues('BTutelaLegale',false,false),
    note: textareaValue,
  },
  {
    key: '6',
    rischio: 'Cyber',
    contract:radioValues('CCyber',false,true),
    bank: radioValues('BCyber',false,false),
    note: textareaValue,
  },
  {
    key: '7',
    rischio: 'Trasporti',
    contract:radioValues('CTrasporti',false,true),
    bank: radioValues('BTrasporti',false,false),
    note: textareaValue,
  },
];

const Polizza = () => {
  const history = useHistory();
//  title: "Il tuo piano assicurativo",

  return(
    <div style={{width:'95%',textAlign:'center',overflow:'scroll'}}>
       <h1 style={{textAlign:'center'}}>Il tuo piano assicurativo</h1>
        <Table columns={columns}  dataSource={data}  pagination={false} className="ant-table-content"/>
        <button
          className="regular-button"
          onClick={() => {
            history.push("/show-policies");
          }}
        >
          Conferma
        </button>
    </div>

  )
};

export default Polizza;