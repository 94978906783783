import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { saveSelectedBusiness } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { CheckOutlined } from "@ant-design/icons";
const businessesFound = [
  {
    uuid: "1",
    name: "Bar Rossi",
    piva: "04957745345",
    ateco: "Codice Ateco 1",
    address: "PADOVA (PD)",
  },
  {
    uuid: "2",
    name: "Ristorante Rossi",
    piva: "04554302845",
    ateco: "Codice Ateco 2",
    address: "ROMA (RO)",
  },
  {
    uuid: "3",
    name: "Pizzeria Rossi",
    piva: "00964345345",
    address: "PADOVA (PD)",
  },
  {
    uuid: "4",
    name: 'Pizzeria "I Rossi"',
    piva: "04697345225",
    address: "ROMA (RO)",
  },
  {
    uuid: "5",
    name: "Bar Del Rosso",
    piva: "04365423009",
    address: "NAPOLI (NA)",
  },
  {
    uuid: "6",
    name: "Bar dei Rossi",
    piva: "05566940821",
    address: "PALERMO (PA)",
  },
];

const RegistryFound = () => {
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchingList, setSearchingList] = useState([]);
  const [stringSearched, setStringSearched] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedBusinessRedux = useSelector(
    (store) => store.reducer.selectedBusiness
  );

  const selectBusiness = (event, b, index) => {
    /* const alreadySelectedElements = document.getElementsByClassName(
      "active-selected-business"
    );
    if (alreadySelectedElements.length !== 0) {
      for (let i = 0; i < alreadySelectedElements.length; ++i)
        alreadySelectedElements[i].classList.remove("active-selected-business");
    }
    const newElement = document.getElementById(`business-found-${index}`);*/
    setSelectedIndex(index);
    // newElement?.classList.add("active-selected-business");
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
      dispatch(saveSelectedBusiness(selectedBusiness));
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedBusinessRedux)) {
      //history.push("/confirm-data");
    }
  }, [selectedBusinessRedux]);

  useEffect(() => {
    if (isSearching) {
      let list = [];
      businessesFound.forEach((item) => {
        if (
          item.name.toLowerCase().search(stringSearched.toLowerCase()) !== -1
        ) {
          list = [...list, item];
        }
      });
      setSearchingList([...list]);
    }
  }, [isSearching, stringSearched]);

  return (
    <React.Fragment>
      {/*<div className="search-results-section">
        <SearchOutlined />
        <input
          className="input-field"
          placeholder="Filtra i risultati"
          style={{ width: "50%" }}
          onChange={(event) => {
            if (event?.target?.value) {
              setIsSearching(true);
              setStringSearched(event.target.value);
            } else {
              setIsSearching(false);
              setStringSearched(null);
            }
          }}
        />
      </div>*/}
      <form className="form-card-content" onSubmit={onSubmit}>
        <ul className="registry-list">
          {isSearching
            ? searchingList.map((b, index) => {
                return (
                  <li
                    id={`business-found-${index}`}
                    className="section-content separator businesses-found"
                    key={b.uuid}
                    onClick={(event) => {
                      selectBusiness(event, b, index);
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>{b.name}</p>
                      <p>{b.piva}</p>
                      <p>{b.address}</p>
                    </div>
                    {selectedIndex === index ? <CheckOutlined /> : null}
                  </li>
                );
              })
            : businessesFound.map((b, index) => {
                return (
                  <li
                    id={`business-found-${index}`}
                    className="section-content separator businesses-found"
                    key={b.uuid}
                    onClick={(event) => {
                      selectBusiness(event, b, index);
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>{b.name}</p>
                      <p>{b.piva}</p>
                      <p>{b.address}</p>
                    </div>
                    {selectedIndex === index ? (
                      <span style={{ fontSize: "1.4em" }}>
                        <CheckOutlined />
                      </span>
                    ) : null}
                  </li>
                );
              })}
        </ul>
        <button
          type="submit"
          className="regular-button"
          onClick={() => {
            history.push("/confirm-data");
          }}
        >
          Conferma
        </button>
      </form>
    </React.Fragment>
  );
};

export default RegistryFound;
