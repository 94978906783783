import React from "react";
import { useHistory } from "react-router-dom";
import * as Survey from 'survey-react';
import 'survey-react/survey.css';

const UserForm = () => {
  const history = useHistory();
  var defaultThemeColors = Survey
  .StylesManager
  .ThemeColors["default"];
  defaultThemeColors['$body-background-color']='#f1f1f1';
  //console.log("jdjdjdjd",defaultThemeColors['$body-background-color']='#f1f1f1')
Survey
    .StylesManager
    .applyTheme();
    var json = { 
    //title: "",
    showQuestionNumbers : "off", 
    pages: [
    {
      questions: [
                  {
                    type: "radiogroup",
                    name: "business",
                    title: "Dove viene svolto l’esercizio dell’attività?",
                    isRequired: true,
                    colCount: 2,
                    defaultValue: "In locali di proprietà",
                    choices: [
                    "In locali di proprietà",
                    "In locali in locazione",
                    "Sono esclusivamente il proprietario dell’immobile",
                    "Altro"
                  ]
                  }, 
                  {
                    type: "radiogroup",
                    name: "collaborators",
                    title: "Nella sua attività si avvale di dipendenti/collaboratori?",
                    isRequired: true,
                    colCount: 2,
                    defaultValue: "Si",
                    choices: [
                      "Si",
                      "No"
                    ]
                  }, 
                  {
                    type: "radiogroup",
                    name: "devices",
                    title: "Utilizza dei dispositivi elettronici (es. pos, registratori di cassa, bilance, macchinari a controllo numerico etc.)?",
                    isRequired: true,
                    colCount: 2,
                    defaultValue: "Si",
                    choices: [
                      "Si",
                      "No"
                    ]
                  },
                  {
                  type: "radiogroup",
                  name: "tools",
                  title: "Ha vetrine, lastre o espositori?",
                  isRequired: true,
                  colCount: 2,
                  defaultValue: "Si",
                  choices: [
                    "Si",
                    "No"
                  ]
                  },
                  {
                    type: "radiogroup",
                    name: "legalAdvice ar",
                    title: "Ritiene utile disporre di consulenza legale a tutela dell’attività e del patrimonio aziendale?",
                    isRequired: true,
                    colCount: 2,
                    defaultValue: "No",
                    choices: [
                      "Si",
                      "No"
                    ]
                  },
                  {
                    type: "radiogroup",
                    name: "store",
                    title: "Conserva dati informatici propri e/o di clienti e utilizza reti e connessioni internet?",
                    isRequired: true,
                    colCount: 2,     
                    defaultValue: "Si",
                    choices: [
                      "Si",
                      "No"
                    ]
                  },
                  {
                    type: "radiogroup",
                    name: "systems",
                    title: "Dispone di impianti fotovoltaici o solari termici presso l’ubicazione dell’attività?",
                    isRequired: true,
                    colCount: 2,
                    defaultValue: "No",
                    choices: [
                      "Si",
                      "No"
                    ]
                },
              ]
            },
    ]};
    var model = new Survey.Model(json);
    /*    
    model.onComplete.add(function (sender) {
    history.push("/polizza");
    });
    */
  return (
    <div style={{width:'100%',overflow:'scroll'}}>
      <h1 style={{textAlign:'center'}}>Informazioni specifiche</h1>
      <div style={{width:'95%',marginLeft:'auto',marginRight:'auto'}}>
          <Survey.Survey model={model}/>
      </div>

      <div style={{textAlign:'center'}}>
      <button
          className="regular-button"
          onClick={() => {
            history.push("/polizza");
          }}
        >
          Conferma
        </button>
        </div>
    </div>
  );

  /*
  const history = useHistory();
  const [showGoToNextPage, setShowGoToNextPage] = useState(false);
  const typeformRef = useRef(null);
  const [isTypeformReady, setIsTypeformReady] = useState(false);
  useEffect(() => {
    const element = document.getElementsByClassName("typeform-container");
    if (element && element[0]) {
      typeformEmbed.makeWidget(
        element[0],
        "https://form.typeform.com/to/TsWSfsPh?typeform-medium=embed-snippet",
        {
          hideFooter: true,
          hideHeaders: true,
          height: "100%",
          width: "100%",
          opacity: "0%",
          buttonText: "Inizia",
          onSubmit: () => {
            //setShowGoToNextPage(true);
            history.push("/needs-analysis");
          },
          onReady: () => {
            setIsTypeformReady(true);
          },
        }
      );
    }
  }, []);
  return (
    <React.Fragment>
      {!isTypeformReady && (
        <span
          style={{
            fontSize: "3rem",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingOutlined />
        </span>
      )}
      <div
        ref={typeformRef}
        className={
          isTypeformReady
            ? "typeform-container"
            : "typeform-container typeform-not-ready"
        }
      />
      {showGoToNextPage ? (
        <button
          className="regular-button"
          onClick={() => {
            history.push("/needs-analysis");
          }}
        >
          Conferma
        </button>
      ) : null}
    </React.Fragment>
  );
  */
};

export default UserForm;
