import logoGen2 from "../style/images/intesa-s-paolo.png";
import React, { useEffect, useState } from "react";
import Wizard from "./Wizard";
import { useLocation } from "react-router-dom";

const Header = ({ showWizard, page }) => {
  const location = useLocation();
  const [amIOnConfirmRegistryPage, setAmIOnConfirmRegistryPage] = useState(
    false
  );
  useEffect(() => {
    if (location.pathname === "/confirm-data") {
      setAmIOnConfirmRegistryPage(true);
    } else {
      setAmIOnConfirmRegistryPage(false);
    }
  }, [location]);

  return (
    <div className="external-header-credits">
      <a
        href="https://www.intesasanpaolo.com/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={logoGen2} className="bnl-logo" alt="Logo Generic Header" />
      </a>
      {/*<h1 className="project-name">Project-cj</h1>*/}
      {showWizard && (
        <div
          className={
            amIOnConfirmRegistryPage
              ? "wizard-header-container wizard-header-container-full-width"
              : "wizard-header-container"
          }
        >
          <Wizard page={page} />
        </div>
      )}
    </div>
  );
};

export default Header;
