import React from "react";
import { useHistory } from "react-router-dom";
import { ProgressBar, Step } from "react-step-progress-bar";

const Wizard = ({ page }) => {
  const history = useHistory();
  return (
    <div className="positioning-of-progress-bar">
      <ProgressBar
        percent={page}
        filledBackground="linear-gradient(to right,  rgb(102, 149, 208),  rgb(164, 208, 171))"
      >
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/confirm-data");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Inizio</p>
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/user-form");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Dettagli</p>
              </div>
            </div>
          )}
        </Step>
        
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/polizza");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Polizza</p>
              </div>
            </div>
          )}
        </Step>
        { /*     
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/needs-analysis");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Rischi</p>
              </div>
            </div>
          )}
        </Step>
         */ }
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/show-policies");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Proposte</p>
              </div>
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${
                accomplished ? "accomplished can-click" : null
              }`}
              onClick={() => {
                if (accomplished) history.push("/overview");
              }}
            >
              {index + 1}
              <div
                className={
                  accomplished
                    ? "wizard-text-step wizard-text-step-active"
                    : "wizard-text-step"
                }
              >
                <p>Pagamento</p>
              </div>
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default Wizard;
