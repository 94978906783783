import React from "react";
import Complete from "../style/images/Complete.svg";

const Comfirmation = () => {
  return (
    <div className="left-side-inside-content">
      <div className="form-data-and-image-container">
        <div className="card-header text-last-page">
          <h1>Congratulazioni!</h1>
          <h2>La tua azienda è protetta!</h2>
        </div>

        <div className="image-side-container" style={{ width: "60%" }}>
          <img
            src={Complete}
            className="image-side"
          />
        </div>
      </div>
    </div>
  );
};

export default Comfirmation;
