import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spin } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import LeftDoc from "./doc/1.pdf";
import RightDoc from "./doc/2.pdf";
import _ from "lodash";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const Overview = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [pagesArray, setPagesArray] = useState([]);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (numPages && _.isEmpty(pagesArray)) {
      loadPages();
    }
  }, [numPages, pagesArray]);

  const loadPages = () => {
    let array = [];
    for (let i = 1; i <= numPages; ++i) {
      array = [...array, i];
    }
    setPagesArray([...array]);
   // console.log(pagesArray);
  };

  return (
    <React.Fragment>
       <div className="card-header" style={{ paddingTop: 0 }}>
        <h1>Termini e condizioni:</h1>
      </div>
      <div style={{width:'100%',display:'inline-flex',overflowY:'scroll'}}>
        <div style={{width:'40%',marginRight:'50px',marginLeft:'120px'}}>
        <h2>DIP, DIP - A</h2>
        <div className="pdf-container">
          <Document
              file={LeftDoc}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Spin tip="Sto caricando il PDF..."/>}
          >
            {pagesArray.length > 0 &&
              pagesArray.map((p) => <Page pageNumber={p} />)}
          </Document>
        </div>
        </div>
        <div style={{width:'40%'}}>
        <h2>Proposta</h2>
        <div className="pdf-container">
          <Document
              file={RightDoc}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Spin tip="Sto caricando il PDF..."/>}
          >
            {pagesArray.length > 0 &&
              pagesArray.map((p) => <Page pageNumber={p} />)}
          </Document>
        </div>
        <div>
        <button
          onClick={() => {
            //history.push("/confirm-payment");
          }}
          className="regular-button otp-button"
        >
          Firma OTP
        </button>
        </div>

        </div>
      </div>
      <div style={{width:'100%',textAlign:'center'}}>
        <button
          onClick={() => {
            history.push("/confirm-payment");
          }}
          className="regular-button"
          >
          Avanti
        </button>
      </div>
    </React.Fragment>
  );
};

export default Overview;
