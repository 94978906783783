import React, { useState } from "react";
import RegistrySearch from "./RegistrySearch";
import InsAgent from "../../style/images/Insurance_Agent.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import RegistryFound from "./RegistryFound";
const businessesFound = [
  {
    value: "1",
    label: "Bar Rossi",
  },
  {
    value: "2",
    label: "Ristorante Rossi",
  },
  {
    value: "3",
    label: "Pizzeria Mario Rossi",
  },
  {
    value: "4",
    label: 'Pizzeria "I Rossi"',
  },
  {
    value: "4",
    label: "Bar Del Rosso",
  },
];

const RegistryPage = () => {
  const [showResults, setShowResults] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="left-side-content first-page-content">
      <div className="first-page-text">
        <RegistrySearch sendShowResults={setShowResults} />
        {showResults && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{ padding: "0.5rem", width: "30rem" }}
              className="fade-in-animation"
            >
              <RegistryFound />
            </div>
          </div>
        )}
        {/*!showResults && (
          <button
            type="submit"
            className="regular-button"
            onClick={() => {
              if (!showResults) {
                setShowResults(true);
              } else {
                history.push("/confirm-data");
              }
            }}
          >
            Conferma
          </button>
        )*/}
      </div>
      <div className="image-side-container">
        <img src={InsAgent} className="image-side image-first-page" />
      </div>
    </div>
  );
};

export default RegistryPage;
